document.addEventListener("DOMContentLoaded", function() {

    const titleHead = document.querySelector(".js-title-head");

    if (titleHead) {

        titleHead.classList.add("show");
    }

    (function() {
        const btnMenuOpen = document.querySelector(".js-open-menu");
        const btnMenuClose = document.querySelector(".js-close-menu");

        const menu = document.querySelector(".menu");
        const body = document.querySelector("body");

        btnMenuOpen.addEventListener("click", () => {
            menu.classList.add("open");
            body.classList.add("no-scroll");
        });

        btnMenuClose.addEventListener("click", () => {
            menu.classList.remove("open");
            body.classList.remove("no-scroll");
        });
    })();


    (function() {
        const btnScroll = document.querySelectorAll(".js-to-scroll");

        btnScroll.forEach(link => {
            link.addEventListener('click', handleClick);
        });
    })();

    function handleClick(event) {
        event.preventDefault();

        const targetId = this.getAttribute("data-scroll");
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            scrollToElement(targetElement);
        }
    }

    function scrollToElement(element) {
        const offsetTop = element.offsetTop;

        window.scrollTo({
            top: offsetTop,
            behavior: "smooth",
        });
    }

    (function() {
        const items = document.querySelectorAll('.js-industry-observer');
        let index = 0;

        const optionsIndustry = {
            rootMargin: '0px',
            threshold: [0.9],
        }

        const observerIndustry = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    showItemsWithDelay();
                    observer.unobserve(entry.target);
                }
            });
        }

        if (items.length > 0) {

            const intersectionObserver = new IntersectionObserver(observerIndustry, optionsIndustry);

            intersectionObserver.observe(items[0]);
        }

        function showItemsWithDelay() {
            if (index < items.length) {
                setTimeout(() => {
                    items[index].classList.add('show');
                    index++;
                    showItemsWithDelay();
                }, 300);
            }
        }
    })();


    (function() {
        const observerImg = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {

                    entry.target.classList.add("show");
                    observer.unobserve(entry.target);
                }
            })
        }

        const optionsImg = {
            rootMargin: '0px',
            threshold: [0.5],
        }

        const targetElement = document.querySelectorAll('.js-img-observer')

        targetElement.forEach(elem => {
            const observer = new IntersectionObserver(observerImg, optionsImg)
            observer.observe(elem)
        })
    })();

    (function() {
        const observerText = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {

                    entry.target.classList.add("show");
                    observer.unobserve(entry.target);
                }
            })
        }

        const optionsText = {
            rootMargin: '0px',
            threshold: [0.8],
        }

        const targetElement = document.querySelectorAll('.js-text-observer')

        targetElement.forEach(elem => {
            const observer = new IntersectionObserver(observerText, optionsText)
            observer.observe(elem)
        })
    })();

    (function() {

        const industryItems = document.querySelectorAll(".main-industry__col");

        industryItems.forEach(elem => {

            elem.addEventListener("click", function(event) {

                const targetId = this.dataset.industry;
                localStorage.setItem("activeElement", targetId);
            });
        })

        const activeElementId = localStorage.getItem("activeElement");

        if (activeElementId) {

            const activeElement = document.getElementById(activeElementId);

            if (activeElement) {

                scrollToElement(activeElement);

                localStorage.removeItem("activeElement");
            }
        }
    })();

});